// plop_marker:layouts_import
import HubspotForm from "./hubspot-form/index.js";
import HubspotOnDarkBackgroundForm from "./hubspot-on-dark-background-form/index.js";
import MondayFormForm from "./monday-form-form/index.js";
import HubspotWithBulletsSideBySideForm from "./hubspot-with-bullets-side-by-side-form/index.js";
import HubspotAndLogosForm from "./hubspot-and-logos-form/index.js";
import ZendeskSideBySideForm from "./zendesk-side-by-side-form/index.js";
import ZendeskForm from "./zendesk-form/index.js";
import HubspotSideBySideForm from "./hubspot-side-by-side-form/index.js";
export default {
    // plop_marker:layouts_add_layout
    HubspotForm,
    HubspotOnDarkBackgroundForm,
    MondayFormForm,
    HubspotWithBulletsSideBySideForm,
    HubspotAndLogosForm,
    ZendeskSideBySideForm,
    ZendeskForm,
    HubspotSideBySideForm
};
