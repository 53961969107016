// plop_marker:layouts_import
import BookAMeetingEmbedded from "./book-a-meeting-embedded/index.js";
import WhatsNewItemsListEmbedded from "./whats-new-items-list-embedded/index.js";
import MarkdownEmbedded from "./markdown-embedded/index.js";
export default {
    // plop_marker:layouts_add_layout
    BookAMeetingEmbedded,
    WhatsNewItemsListEmbedded,
    MarkdownEmbedded
};
