import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphFieldsConfig from "segments/desktop/core-components/full-paragraph/fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Ada Chatbot",
    fields: {
        fullParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Full Paragraph",
            fields: FullParagraphFieldsConfig
        }
    }
};
