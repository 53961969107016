function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import FullParagraphAdvancedFieldsConfig from "../../../core-components/full-paragraph/fields-config";
import ParagraphConfig from "../../../core-components/paragraph/fields-config";
const { buttonTopMargin, textColor, topicBottomMargin, buttonConfig, assetUnderParagraph } = FullParagraphAdvancedFieldsConfig, restOfParagraphConfig = _object_without_properties(FullParagraphAdvancedFieldsConfig, [
    "buttonTopMargin",
    "textColor",
    "topicBottomMargin",
    "buttonConfig",
    "assetUnderParagraph"
]);
export default {
    type: FIELD_TYPES.NESTED,
    label: "Book A Meeting",
    fields: {
        findTimeParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Find Time Full Paragraph",
            fields: restOfParagraphConfig
        },
        bookingConfirmedParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Booking Confirmed Full Paragraph",
            fields: restOfParagraphConfig
        },
        anotherOptionParagraph: {
            type: FIELD_TYPES.NESTED,
            label: "Another Option Paragraph",
            fields: ParagraphConfig
        },
        fallbackUrl: {
            type: FIELD_TYPES.TEXT,
            label: "Fallback url"
        },
        hyperLink: {
            type: FIELD_TYPES.NESTED,
            label: "Hyperlink",
            fields: {
                text: {
                    type: FIELD_TYPES.TEXT,
                    label: "Text"
                },
                href: {
                    type: FIELD_TYPES.TEXT,
                    label: "href"
                }
            }
        }
    }
};
