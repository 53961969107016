function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import { tagsSupportedColors } from "/constants/color-picker-constants";
import assetFieldsConfig from "components/core/asset/fields-config";
export default {
    tagText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    tagSubtitleText: {
        type: FIELD_TYPES.TEXT,
        label: "Tag Subtitle Text",
        translatable: true
    },
    tagBackgroundColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: tagsSupportedColors
    },
    tagValue: {
        type: FIELD_TYPES.TEXT,
        label: "Key (DEPRECATED)",
        labelDescription: "Will be removed (copy to use case key)"
    },
    overridePageCluster: {
        type: FIELD_TYPES.CLUSTERS,
        label: "Cluster key",
        labelDescription: "Key which represents the selected tag cluster"
    },
    overridePageSubCluster: {
        type: FIELD_TYPES.SUB_CLUSTERS,
        label: "Use Case key",
        labelDescription: "Key which represents the selected tag use case"
    },
    galleryImage: {
        type: FIELD_TYPES.NESTED,
        label: "Gallery image",
        labelDescription: "Images carousel component related only",
        fields: _object_spread({}, assetFieldsConfig.image.fields)
    },
    initiallySelected: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Initially selected"
    }
};
